import { captureException } from '@sentry/nextjs';
import axios from 'axios';

import env from '@/helpers/server/env';

export const newBackendHttp = axios.create({
  baseURL: typeof window === 'undefined' ? env.NEXT_PUBLIC_AZ_BACKEND_URL : '/api',
  withCredentials: true,
});

newBackendHttp.defaults.headers.common['request-source'] = 'web';

newBackendHttp.interceptors.response.use((response) => response, onRejected);

export const newBackendHttpV2 = axios.create({
  baseURL: typeof window === 'undefined' ? env.NEXT_PUBLIC_AZ_BACKEND_URL_V2 : '/api/v2',
  withCredentials: true,
});

newBackendHttpV2.defaults.headers.common['request-source'] = 'web';

newBackendHttpV2.interceptors.response.use((response) => response, onRejected);

async function onRejected(error: any) {
  const originalRequest = error.config;
  if (
    (error.response?.status === 401 || error.response?.status === 403) &&
    !originalRequest._retry
  ) {
    originalRequest._retry = true;

    try {
      await axios.get('/api/auth/me');

      return axios(originalRequest);
    } catch (error) {
      captureException(error, { level: 'fatal' });
      await axios.get('/api/auth/logout');
    }
  }

  return Promise.reject(error);
}
