import { DefaultSeo } from 'next-seo';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { Manrope } from 'next/font/google';
import Head from 'next/head';
import { useRouter } from 'next/router';
import NextTopLoader from 'nextjs-toploader';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { _IS_SEO_DISABLED_ } from '@/helpers/condition/is-seo-disabled';

import { useFlocktory } from '@/hooks/useFlocktory';
import { useMetrikaPageHits } from '@/hooks/useMetrikaPageHits';

import { persistor, store } from '@/store/store';

import { SEO_CONSTANTS, SEO_DEFAULT } from '@/constants/seo.constant';

import 'styles/main.scss';
import 'swiper/scss';

import 'react-toastify/dist/ReactToastify.css';

const ModalContainer = dynamic(() => import('@/components/ui/modals/ModalContainer'), {
  ssr: false,
});
const Scripts = dynamic(() => import('@/components/scripts'), { ssr: false });

const manrope = Manrope({
  weight: ['400', '500', '600', '700'],
  subsets: ['cyrillic-ext'],
  variable: '--font-manrope',
  display: 'swap',
  style: 'normal',
  preload: true,
  adjustFontFallback: true,
});

declare global {
  interface Window {
    __REACT_DEVTOOLS_GLOBAL_HOOK__: any;
  }
}

function App({ Component, pageProps: { ...pageProps } }: AppProps) {
  const router = useRouter();

  useMetrikaPageHits();

  useFlocktory();

  const tree = (
    <div className={`${manrope.variable}`}>
      <Component {...pageProps} />
      <ModalContainer />
      <div id="myportal" />
    </div>
  );
  useEffect(() => {
    if (process.env.NODE_ENV === 'production' && typeof window !== 'undefined') {
      const hook = window.__REACT_DEVTOOLS_GLOBAL_HOOK__;
      if (typeof hook === 'object') {
        Object.keys(hook).forEach((key) => {
          hook[key] = typeof hook[key] === 'function' ? () => {} : null;
        });
      }
    }
  }, []);

  return (
    <Provider store={store}>
      <NextTopLoader color="#ff283d" showSpinner={false} />
      <DefaultSeo
        dangerouslySetAllPagesToNoIndex={_IS_SEO_DISABLED_}
        dangerouslySetAllPagesToNoFollow={_IS_SEO_DISABLED_}
        canonical={SEO_CONSTANTS.website + router.asPath.split('?')[0]}
        {...SEO_DEFAULT(SEO_CONSTANTS.website + router.asPath)}
      />
      <PersistGate persistor={persistor} loading={tree}>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5" />
        </Head>
        {tree}
        <Scripts />
      </PersistGate>
    </Provider>
  );
}

export default App;
